import React, { useState, useEffect } from "react";
import { PricingPlan } from "./pricing-plan";
import {
  Unstable_Grid2 as Grid,
  SvgIcon,
  Button,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import apiCalls from "src/api";
import { useSearchParams } from "react-router-dom";
import { useAuthContext } from "src/contexts/auth-context";
import { paths } from "src/paths";
import ReferenceToken from "./reference-token";
import { setCachedUser } from "src/utils/cache";

export const PricingPlansPage = (props) => {
  const icon = (
    <SvgIcon>
      {" "}
      <AutoGraphIcon />{" "}
    </SvgIcon>
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [plans, setPlans] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const openBackdrop = () => {
    setOpen(true);
  };
  const fetchPlans = () => {
    apiCalls
      .getPlans(user.id)
      .then((resp) => {
        if (resp.status < 300) {
          setPlans(resp.data.results);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!plans.length) {
      fetchPlans();
    }
    const is_success = searchParams.get("success");
    if (is_success !== null) {
      toast.success("Payment was successfull");
      openBackdrop();
      const intervalId = setInterval(() => {
        apiCalls.getCustomerPermissions().then((resp) => {
          if (resp.status < 300) {
            user.user_permissions = resp.data.data;
            setCachedUser(user);
          }
        });
        apiCalls.getSubsciptions(user.id).then((resp) => {
          if (resp.status === 200) {
            localStorage.setItem(
              "subscription",
              JSON.stringify(resp.data.data)
            );
            clearInterval(intervalId);
            handleClose();
            navigate(paths.index);
          }
        });
      }, 500);
    }
    const cancel = searchParams.get("cancel");
    if (cancel !== null) {
      const sub_id = searchParams.get("sub_id");
      if (sub_id !== null) {
        apiCalls
          .cancelClientKey(sub_id, user.id)
          .then((resp) => {
            if (resp.status < 300) {
              toast.error("Payment was canceled");
            }
          })
          .catch((err) => {
            toast.error("Something went wrong");
          });
      }
    }
  }, []);

  const openConfirmationDialog = () => {
    setIsOpen(true);
  };
  return (
    <>
      {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        sx={{ position: "absolute", top: "90px", left: "230px", width: "75%" }}
        container
        spacing={3}
      >
        <Grid xs={12} md={12} justifyContent="flex-end" alignContent={"end"}>
          <Button
            variant="contained"
            onClick={() => {
              localStorage.clear();
              navigate("/login");
            }}
          >
            Logout
          </Button>
        </Grid>
        <Grid
          xs={12}
          md={12}
          justifyContent={"flex-start"}
          alignContent={"center"}
        >
          <ReferenceToken />
        </Grid>
        {plans.map((x) => {
          return (
            <Grid
              key={x.id}
              xs={Math.floor(12 / plans.length)}
              md={Math.floor(12 / plans.length)}
            >
              <PricingPlan
                plan={x}
                plan_type={x.name}
                cta={"Subscribe"}
                currency={"$"}
                description={"Subscription Details"}
                features={["Investment Calculator"]}
                icon={icon}
                name={x.name.toUpperCase()}
                popular={true}
                price={"500"}
              />
            </Grid>
          );
        })}
        {/* <Grid xs={4} md={4}>
                <PricingPlan plan_type={'base'} cta={'Subscribe'} currency={'$'} description={'Subscription Details'}
                    features={['Investment Calculator']} icon={icon} name={'Base'} popular={true} price={'500'} />
            </Grid>
            <Grid xs={4} md={4}>
                <PricingPlan plan_type={'essentials'} cta={'Subscribe'} currency={'$'} description={'Subscription Details'}
                    features={['Investment Calculator', 'Retirement Calculator']} icon={icon} name={'Essentials'} popular={true} price={'800'} />
            </Grid>
            <Grid xs={4} md={4}>
                <PricingPlan plan_type={'premium'} cta={'Subscribe'} currency={'$'} description={'Subscription Details'}
                    features={['Investment Calculator', 'Retirement Calculator', 
                    'Investment horizon', 'College Planning', 'Tax Calculators']} icon={icon} name={'Premium'} popular={true} price={'3000'} />
            </Grid> */}
      </Grid>
      <Dialog open={isOpen}>
        <DialogContent></DialogContent>
      </Dialog>
    </>
  );
};
